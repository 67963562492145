import React from 'react';
import css from './report.module.scss';
import LinkArrow from '../../components/button/link-arrow';
import { BLOG } from '../../constants/routes';

export default function Report() {
  return (
    <div className={css.report}>
      <div className={css.background} />
      <div className={css.container}>
        <div className={css.title}>
          <h1>
            <span className={css.firstWord}>Face</span>
            <span className={css.space}>&nbsp;</span>
            <span className={css.group}>
              <span className={css.secondWord}>to</span>
              <span className={css.space}>&nbsp;</span>
              <span className={css.thirdWord}>Face</span>
              <span className={css.newLine}><br/></span>
            </span>
            <span className={css.fourthWord}>with</span>
            <span className={css.space}>&nbsp;</span>
            <span className={css.group}>
              <span className={css.fifthWord}>the</span>
              <span className={css.space}>&nbsp;</span>
              <span className={css.sixthWord}>space</span>
            </span>
          </h1>
        </div>
        <div className={css.article}>
          <div className={css.column}>
            <p>
              Abandoned hangars with Soviet spaceships "Buran" and the rocket-carrier "Energia" are located in Kazakhstan, on the territory of Baikonur cosmodrome. That means that you will pass a 30 km long way by foot through the desert steppe, carrying a backpack with your equipment behind your back, surviving sudden temperature changes, meet wild horses and camels and of course look into the bottomless starry sky.
            </p>
            <p>
              This tour will perfectly work for experienced trekkers, urbexers and physically fit, hardy people, ready to spend over 3 nights in field conditions.
            </p>
          </div>
          <div className={css.column}>
            <p>
              Professional english speaking guide will meet you in the city Kyzilorda, Kazakhstan and by the night in a company of a small group you will be taken by car to the start point in a desert where the adventure begins.
            </p>
            <p>
              You will explore famous abandoned Soviet spaceships and rocket-carrier of the "Energia Buran" space program and fully dive into the real culture of the urban
            </p>
          </div>
        </div>
        <h2>
          READ OUR REAL TRIP REPORT TO UNDERSTAND HOW THE TOURS WORK
        </h2>
        <LinkArrow to={BLOG.path}>See report</LinkArrow>
      </div>
    </div>
  );
}
