import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import css from './booking-modal.module.scss';
import { useOutsideClickTracker } from '../../hooks/shared-hooks';
import Form from '../form/form';
import classNames from 'classnames';

BookingModal.propTypes = {
  close: PropTypes.func.isRequired
}

export default function BookingModal({ close }) {
  const popupRef = useRef(null);
  const [successfullySubmited, setSuccessfullySubmited] = useState(false);

  useOutsideClickTracker(popupRef, () => {
    close();
  })

  return (
    <div className={css.bookingPopup}>
      <div className={classNames(css.container, successfullySubmited ? css.success : '')} ref={popupRef}>
        <div className={css.close} onClick={close}>
          ×
        </div>
        {!successfullySubmited && (
          <div className={css.booking}>
            <h1>BOOK THE TOUR</h1>
            <Form key='popup-booking-form' onSubmit={() => setSuccessfullySubmited(true)} />
          </div>
        )}
        {successfullySubmited && (
          <div>
            <h1>Thank you!</h1>
            <p>
              We’ve received your inquiry and will get back to you shortly.
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
