import React from 'react';
import image02 from '../../assets/blog/2.webp';
import image03 from '../../assets/blog/3.webp';
import image04 from '../../assets/blog/4.webp';
import image05 from '../../assets/blog/5.webp';
import image06 from '../../assets/blog/6.webp';
import image07 from '../../assets/blog/7.webp';
import image08 from '../../assets/blog/8.webp';
import image09 from '../../assets/blog/9.webp';
import image10 from '../../assets/blog/10.webp';
import image11 from '../../assets/blog/11.webp';
import image12 from '../../assets/blog/12.webp';
import image13 from '../../assets/blog/13.webp';
import image14 from '../../assets/blog/14.webp';
import image15 from '../../assets/blog/15.webp';
import image16 from '../../assets/blog/16.webp';
import image17 from '../../assets/blog/17.webp';
import image18 from '../../assets/blog/18.webp';
import image19 from '../../assets/blog/19.webp';
import image20 from '../../assets/blog/20.webp';
import image21 from '../../assets/blog/21.webp';
import image22 from '../../assets/blog/22.webp';
import image23 from '../../assets/blog/23.webp';
import image24 from '../../assets/blog/24.webp';
import image25 from '../../assets/blog/25.webp';
import image26 from '../../assets/blog/26.webp';
import image27 from '../../assets/blog/27.webp';
import image28 from '../../assets/blog/28.webp';
import image29 from '../../assets/blog/29.webp';
import image30 from '../../assets/blog/30.webp';
import image31 from '../../assets/blog/31.webp';
import image32 from '../../assets/blog/32.webp';
import image33 from '../../assets/blog/33.webp';
import image34 from '../../assets/blog/34.webp';
import image35 from '../../assets/blog/35.webp';
import image36 from '../../assets/blog/36.webp';
import image37 from '../../assets/blog/37.webp';
import image38 from '../../assets/blog/38.webp';
import image39 from '../../assets/blog/39.webp';
import image40 from '../../assets/blog/40.webp';
import image41 from '../../assets/blog/41.webp';
import image42 from '../../assets/blog/42.webp';
import image43 from '../../assets/blog/43.webp';
import image44 from '../../assets/blog/44.webp';
import image45 from '../../assets/blog/45.webp';
import image46 from '../../assets/blog/46.webp';
import image47 from '../../assets/blog/47.webp';
import image48 from '../../assets/blog/48.webp';
import image49 from '../../assets/blog/49.webp';
import image50 from '../../assets/blog/50.webp';
import image51 from '../../assets/blog/51.webp';
import image52 from '../../assets/blog/52.webp';
import image53 from '../../assets/blog/53.webp';
import image54 from '../../assets/blog/54.webp';
import image55 from '../../assets/blog/55.webp';
import image56 from '../../assets/blog/56.webp';
import image57 from '../../assets/blog/57.webp';
import image58 from '../../assets/blog/58.webp';
import image59 from '../../assets/blog/59.webp';
import image60 from '../../assets/blog/60.webp';
import image61 from '../../assets/blog/61.webp';
import image62 from '../../assets/blog/62.webp';
import image63 from '../../assets/blog/63.webp';
import image64 from '../../assets/blog/64.webp';
import Layout from '../../components/layout/layout';
import css from './blog.module.scss';
import LinkArrow from '../../components/button/link-arrow';
import { CONTACT, GENERAL } from '../../constants/routes';
import { Link } from 'react-router-dom';

export default function Blog() {
  return (
    <Layout>
      <div className={css.blog}>
        <div className={css.header}>
          <div className={css.textGroup}>
            <h1><span>Road to </span><span>baikonur</span></h1>
            <p>by urbexstorm</p>
          </div>
        </div>
        <div className={css.body}>
          <p>
            Not so many places exist on Earth that could be so desirable for all kinds of travelers, whether they are ordinary backpackers, professional tourists, adrenaline junkies or dedicated urbexers like a world's famous cosmodrome Baikonur in Kazakhstan. Except being the launch pad for the first man in space – Yuriy Gagarin, this site became a cradle of a large number of Soviet space projects, the crown of which was the legendary reusable orbital spacecraft "Buran" and the heavy launch vehicle "Energia". 
          </p>
          <img src={image02} alt='Baikonur' />
          <img src={image03} alt='Baikonur' />
          <img src={image04} alt='Baikonur' />
          <div className={css.group}>
            <p>
              Since the fall of the Soviet Union the major part of all space programs got closed because of the lack of financing. "Buran-Energia" project was not an exception. More than three decades ago a couple of spacecrafts and a full scale model of an Energia rocket carrier were closed forever behind massive sliding gates of the huge hangars somewhere on the cosmodrome's territory in the middle of Kazakh desert.
            </p>
            <p>
              Despite quite popular official tours, organized by the cosmodrome management, Buran facilities were staying covered by mystery. Until the year 2015, when one urban explorer  published a sensational article where he showed the present condition of the former pride of the Soviet space industry, covered by a thick layer of the bird droppings left inside abandoned technical hangars.
            </p>
            <p>
              Of course the biggest interest arose inside of the URBEX community. Urban explorers and all abandoned lovers from all over the world were dreaming about getting to this site, however the lack of information about the way to get to the guarded cosmodrome territory and a great distance itself were  remaining a significant stopping factor. At the same time all official requests to the cosmodrome's administration about letting their tourists explore the hangars with "Burans" were denied - condition of abandoned constructions is bad, it's too dangerous.
            </p>
            <p>
              Surely, me and my colleagues in urbex decided to accept the challenge and successfully reached the Baikonur in September 2017. Overall everything worked out well and we managed to explore the complex of buildings with abandoned spaceships from top to bottom and get back without being noticed by guards. What we saw with our own eyes made a strong impression on us and we came up with an idea to share our experience with a small group of our constant clients - experienced travelers and real urbex lovers. By that time more and more illegal tourists started visiting that place every time bringing too much attention to it, which in turn made Baikonur the place number one, a true Mecca in the world of Urban Exploration. Popularity did not benefit the security situation. Just one month before the departure of our second expedition we got the news about another group got caught on the approach to the hangar. According to unverified information local guards started using night vision systems for catching trespassers arriving from the desert until it's dark. Of course after hearing this we could not risk the group, even though it wasn't a big one, because the new-appeared factor caused a zero chance of reaching our target as the only way we could use was a many kilometers flat without a single cover except darkness. So, unfortunately our expedition was canceled.
            </p>
            <p>
              However the idea of organizing the first in history real urbex tour to Burans was not leaving us through the years. But not a long time ago the stars finally aligned and our tracking shoes touched the Kazakh desert once again. In this topic I will try to describe in detail the whole process of our expedition and share the unforgettable experience of our one time in life adventurous tour to the legendary Baikonur.
            </p>
          </div>
          <p>Baikonur cosmodrome is situated in the Kazakh Steppe, some 90 meters above sea level, it is 200 kilometers to the east of the Aral Sea and north of the Syr Darya river.</p>
          <img src={image05} alt='Baikonur' />
          <div className={css.group}>
            <p>
              In 1955, the Soviet Ministry of Defence issued a decree and founded the Baikonur Cosmodrome. It was originally built as the chief base of operations for the Soviet space program. The Cosmodrome served as the launching point for Sputnik 1 and Vostok 1. The launchpad used for both missions was renamed "Gagarin's Start" in honor of Soviet cosmonaut Yuri Gagarin, who piloted Vostok 1 and became the first human in outer space.
            </p>
            <p>
              After the fall of the Soviet Union, Russia ratified an agreement with Kazakhstan that allowed it to lease the spaceport until 2050. Under the current Russian management, Baikonur remains a busy spaceport, with numerous commercial, military, and scientific missions being launched annually.
            </p>
            <p>
              As it was mentioned before, hangars with abandoned spacecrafts are not available for official visits. The only way to see them is to go on a real urbex mission, that includes all aspects of the real urban exploration adventure.
            </p>
            <p>
              So our meeting point was located in the nearest plane accessible city to Baikonur called Kyzylorda. Here we rented an apartment for the whole group to store some of our stuff and rest after the long flight. On the next day we got all our backpacks full of the necessary equipment and provision, checked our maps and after the last preparations we left our block house. The car was already waiting for us. We packed all our backpacks and after two and a half hours the driver dropped us in the secret point in a Kazakh desert, where our journey began.
            </p>
            <p>
              It was around 5 pm when we started moving away from the road into the endless desert.
            </p>
          </div>
          <img src={image06} alt='Baikonur' />
          <div className={css.group}>
            <p>In order to reach our target we had to move through the desert around 27-30 km by foot with heavy backpacks, avoiding all potential meetings with anyone whom we could potentially meet on our way.</p>
            <p>
              There are a lot of destroyed soviet military silo facilities in that region, so local gangs sometimes come to the desert by big offroads to pick up some metal and copper cables left in there. The cosmodrome's guards also make patrol raids to catch them so actually it doesn't matter how far away you are from the active cosmodrome you should be all-time attentive and careful.  Moreover the desert is full of different poisonous animals like snakes and scorpions. That's why everyone who comes here should pay a lot of attention to the right trekking clothes and equipment quality. We were all dressed in comfortable closed outfits with high trekking shoes, which we broke in well in advance.
            </p>
          </div>
          <div>
            <img src={image07} alt='Baikonur' />
            <p><i>Desert rangers</i></p>
          </div>
          <p>
            For those who don't have any experience in long trekking walks I might say that this route is not the most complicated one - it mostly flat, loose ground  alternating with hard salty crust from dried salt marshes. But as we needed to get to the abandoned hangars in one night, before the sunrise, the tempo of our walk had to be kept at a relatively high level. And that's where spiky bushes and numerous desert gopher burrows made some troubles, mostly when it started getting dark and you just don't see where you step because you can't use a lamp due to the stealth mode. Luckily there were a lot of dirt roads and we were using them as long as they were on our way.
          </p>
          <div>
            <img src={image08} alt='Baikonur' />
            <p><i>Going into the sunset along the bottom of a dried salt marsh</i></p>
          </div>
          <p>
            Sometimes it is possible to meet wild horses and  camels on these roads, but also you should remember to be attentive to everyone you might see here, be it nomadic shepherds or rare cars passing through these deserted places.
          </p>
          <div>
            <img src={image09} alt='Baikonur' />
            <p><i>Camels on the way</i></p>
          </div>
          <p>When the sun hid behind the horizon, the temperature in the desert harshly dropped down from 25 to 5 degrees Celsius. Luckily the high walking tempo and active thermal underwear were helping a lot.</p>
          <img src={image10} alt='Baikonur' />
          <div className={css.group}>
            <p>
              Finally our group saw the lights of the cosmodrome launch pad, from where rockets still fly into space. A bit farther was our target - abandoned hangars with spaceships. When you walk in the desert at night it is pretty hard to visually feel the distances, so despite seeing the constructions on the horizon, it was still a tangible distance to pass.
            </p>
            <p>
              We hurried up in order to come in time before the sunrise, and here is the first perimeter of the territory- an old barbed wire fence with restriction signs.
            </p>
          </div>
          <img src={image11} alt='Baikonur' />
          <p>
            A few minutes after crossing the fence we saw the bright light of the car coming towards us. “Fall down!”- everybody lay down on the ground. Patrol  car quickly passed along the road just in a couple tens of meters away from us.  Luckily  everything worked out well and soon the last final steps were separating us from the goal. Under the bottomless starry sky the mighty hangar was staying like a huge tower, singing it's evil song of hundreds broken windows and metal panels on the wind.
          </p>
          <img src={image12} alt='Baikonur' />
          <div className={css.group}>
            <p>
              After squeezing ourselves into the hidden entrance we've got into some technical room on the first floor of this giant building. Now the idea was to find an appropriate shelter somewhere in the most hidden depth to organize our place of stay. The spot in the corner of some ventilation room seemed to be safe and soon all participants fell asleep after the exhausting night trek.
            </p>
            <p>Next day we woke up closer to midday from the bright light of the sun coming through the grate.</p>
          </div>
          <img src={image13} alt='Baikonur' />
          <p>We slowly got out from our shelter to take a look around. It was a monstrous metal workshop with many platforms inside built around the main stage in the middle. Old rusty mechanisms and broken glass were lying on the floor.</p>
          <img src={image14} alt='Baikonur' />
          <p>We got closer to the edge and looked down – Yes! It is there, the rocket! Unbelievable...</p>
          <img src={image15} alt='Baikonur' />
          <img src={image16} alt='Baikonur' />
          <div className={css.group}>
            <p>
              Energia was a 1980's super-heavy lift launch vehicle. It was designed by NPO Energia of the Soviet Union as part of the Buran program for a variety of payloads including the Buran spacecraft. The Energia used four strap-on boosters each powered by a four-chamber RD-170 engine burning kerosene/LOX, and a central core stage with four single-chamber RD-0120 engines fueled by liquid hydrogen/LOX.
            </p>
            <p>
              The launch vehicle had the capacity to place about 100 tonnes in Low Earth orbit, up to 20 tonnes to geostationary orbit and up to 32 tonnes by translunar trajectory into lunar orbit.
            </p>
            <p>The launch vehicle made just two flights before being discontinued.</p>
          </div>
          <img src={image17} alt='Baikonur' />
          <img src={image18} alt='Baikonur' />
          <p>
            Nowadays a huge almost 60 meters high  model of the heavy rocket is locked inside the Full Scale Stand of Equipment (FSSE) which is represented by a 17 storey building, rising tall in the middle of the desert.
          </p>
          <div>
            <img src={image19} alt='Baikonur' />
            <p><i>FSSE side view</i></p>
          </div>
          <p>
            The FSSE building is equipped with a full size gate on the rails that lets a Rocket to get into it in a horizontal position. All heavy equipment and spacecrafts are traveling around the cosmodrome territory on special platforms using the web of railways.
          </p>
          <div>
            <img src={image20} alt='Baikonur' />
            <p><i>Heavy rocket carrier Energia –M with Buran during the transportation by rails</i></p>
          </div>
          <p>Now we are staying here, inside this rusty metal workshop, contemplating a former power of the Soviet space program...</p>
          <img src={image21} alt='Baikonur' />
          <img src={image22} alt='Baikonur' />
          <img src={image23} alt='Baikonur' />
          <img src={image24} alt='Baikonur' />
          <p>
            The sound in this abandoned building is spreading so loud. One wrong step on some unstable metal panel or a piece of glass resonates in the whole construction. We were moving around maximum carefully and only camera shutters and wind in constructions were disturbing this mystical silence.
          </p>
          <img src={image25} alt='Baikonur' />
          <img src={image26} alt='Baikonur' />
          <p>
            Endless staircase leads us down to the lower levels, every platform gives a new perspective of the great Energia rocket. It is not possible not to stop and think about it's majestical power.
          </p>
          <img src={image27} alt='Baikonur' />
          <img src={image28} alt='Baikonur' />
          <img src={image29} alt='Baikonur' />
          <img src={image30} alt='Baikonur' />
          <div>
            <img src={image31} alt='Baikonur' />
            <p><i>Cosmic selfie!</i></p>
          </div>
          <img src={image32} alt='Baikonur' />
          <img src={image33} alt='Baikonur' />
          <p>Finally we reached the bottom of the FSSE building. With no doubt this is the best place to admire all the beauty and grandeur of ENERGIA.</p>
          <img src={image34} alt='Baikonur' />
          <img src={image35} alt='Baikonur' />
          <p>
            However, our time on the first floor was limited. Sometimes guards are coming here in silence to check if there are uninvited guests inside. After taking a couple of iconic photos of the rocket the group of adventurers came back to the higher levels in order to have some meal in the improvised basecamp.
          </p>
          <div>
            <img src={image36} alt='Baikonur' />
            <p><i>Lunch on the 5 star restaurant's terrace</i></p>
          </div>
          <div>
            <img src={image37} alt='Baikonur' />
            <p><i>The group members sharing the day's experience over coffee</i></p>
          </div>
          <img src={image38} alt='Baikonur' />
          <p>
            From this height it's possible to see all the major facilities of the cosmodrome, including the legendary start position of Energia-Buran.
          </p>
          <img src={image39} alt='Baikonur' />
          <p>
            All around is abandoned, only patrol cars from time to time come here to check if there are no Kazakh Gangs scrapping the metal.
          </p>
          <img src={image40} alt='Baikonur' />
          <div>
            <img src={image41} alt='Baikonur' />
            <p><i>Energia-Buran at the site ready to launch</i></p>
          </div>
          <p>
            Here is also our next target to be seen in the desert's background – big white shabby building of installation and filling complex with Buran spacecrafts.
          </p>
          <img src={image42} alt='Baikonur' />
          <img src={image43} alt='Baikonur' />
          <p>A modern Soyuz-MC rocket is also here, waiting for a launch at the nearby launch site.</p>
          <img src={image44} alt='Baikonur' />
          <p>The next morning we will make our way from here to Burans using a secret passage, but first a good rest is needed.</p>
          <div>
            <img src={image45} alt='Baikonur' />
            <p><i>Meeting a sundown on Baikonur</i></p>
          </div>
          <div className={css.group}>
            <p>
              The night passed relatively calmly. Despite some sounds of the car doors somewhere far down nearby our hangar no one interrupted our sleep. We woke up a couple hours before the sunrise, packed our things and started our way to the hangar with Burans.
            </p>
            <p>
              This was one of the most responsible moments, our goal was not to get noticed by guards, that's why we had to be maximum quiet and stealthy. As real special agents we used some part of an abandoned underground tunnel system in order to shorten our way between two buildings not showing up much. Later we appeared in front of the installation complex itself.
            </p>
          </div>
          <img src={image46} alt='Baikonur' />
          <div className={css.group}>
            <p>
              This is another monstrous building with a lot of laboratories inside.
            </p>
            <p>
              A separate Assembly and Fueling Complex (known as MZK) was built specifically for the Energia-Buran program at Site 112A. It is 150 meters long, 80 meters wide and 70 meters high. The facility is built  around a metal frame designed to withstand possible explosions during the hazardous operations.
            </p>
            <p>
              Same as before, after sneaking into the building we found a stairway and quickly moved up to the higher levels. Higher means safer here.
            </p>
            <p>The view that opened was no doubt stunning.</p>
          </div>
          <div>
            <img src={image47} alt='Baikonur' />
            <p><i>Space eagles tomb</i></p>
          </div>
          <p>
            After the demise of the Energia-Buran program, a test prototype of the orbiter designated OK-ML was stored inside the MZK building. On May 24, 1995, the second flight-worthy Buran orbiter was also moved inside MZK. In the following years, the main doors of the building lost their ability to move, turning the MZK facility into a sarcophagus for both vehicles.
          </p>
          <img src={image48} alt='Baikonur' />
          <p>The group started slowly moving down closer to spacecrafts, same as it was in the previous location.</p>
          <img src={image49} alt='Baikonur' />
          <p>The heart beats here like never before – we are staying so close to it!</p>
          <img src={image50} alt='Baikonur' />
          <p>
            The construction of the Buran spacecraft began in 1980, and by 1984 the first full-scale orbiter was rolled out. Over 1000 factories and institutions all over the Soviet Union were involved in construction and development.
          </p>
          <img src={image51} alt='Baikonur' />
          <div className={css.group}>
            <p>The dry mass of Buran-class orbiters is quoted as 62 tonnes, with a maximum payload of 30 tonnes, for a maximum launch mass of 105 tonnes.</p>
            <p>
              The only orbital launch of a Buran-class orbiter, 1K1 occurred at 03:00:02 UTC on 15 November 1988 from Baikonur Cosmodrome launch pad 110/37.    Buran was lifted into space, on an uncrewed mission, by the specially designed Energia rocket. The automated launch sequence performed as specified, and the Energia rocket lifted the vehicle into a temporary orbit before the orbiter separated as programmed. After boosting itself to a higher orbit and completing two orbits around the Earth, the engines fired automatically to begin the descent into the atmosphere, return to the launch site, and horizontal landing on a runway.
            </p>
          </div>
          <div>
            <img src={image52} alt='Baikonur' />
            <p><i>The legendary start</i></p>
          </div>
          <div>
            <img src={image53} alt='Baikonur' />
            <p><i>Buran after the automatic landing</i></p>
          </div>
          <p>In 1989, it was projected that Buran would have an uncrewed second flight by 1993, with a duration of 15–20 days. Although the Buran programme was never officially canceled, the dissolution of the Soviet Union led to funding drying up and this flight never took place.</p>
          <img src={image54} alt='Baikonur' />
          <p>
            The work of thousands engineers is buried in this temple of space technologies and only rare illegal tourists and desert animals are coming here from time to time...
          </p>
          <img src={image55} alt='Baikonur' />
          <div>
            <img src={image56} alt='Baikonur' />
            <p><i>Face to face with the space</i></p>
          </div>
          <div className={css.group}>
            <p>The most tricky thing of course is to get inside the spacecraft.</p>
            <p>
              But once you are in good physical shape nothing is impossible. Inside you can still see some original interior: cockpit with two seats, some equipment and even an engine.
            </p>
          </div>
          <img src={image57} alt='Baikonur' />
          <p>Dashboard is unfortunately seriously destroyed.</p>
          <img src={image58} alt='Baikonur' />
          <p>
            The real docking module "IKAR'' inside of the abandoned spacecraft "BURIA" (Eng. "Storm") left on the cosmodrome Baikonur in 95% readiness. It was designed to be used by astronauts for entering the cargo department of the spaceship or outer space.
          </p>
          <div>
            <img src={image59} alt='Baikonur' />
            <p><i>IKAR docking module</i></p>
          </div>
          <p>
            The most impressive thing was to see all this hi-tech equipment left here after so many years of abandonment.
          </p>
          <div>
            <img src={image60} alt='Baikonur' />
            <p><i>Docking module from inside</i></p>
          </div>
          <p>
            Here you can image yourself a real astronaut in a zero gravity.
          </p>
          <img src={image61} alt='Baikonur' />
          <p>
            In accordance with the plans of Soviet engineers Buran spacecraft could carry nuclear weapons and steel American satellites from orbit.
          </p>
          <img src={image62} alt='Baikonur' />
          <p>The cargo department is pretty spacious and equipped with a special arm-manipulator for work in outer space.</p>
          <div>
            <img src={image63} alt='Baikonur' />
            <p><i>Cargo department</i></p>
          </div>
          <div className={css.group}>
            <p>Meanwhile the time is hurring us up, we have to leave before the late morning.</p>
            <p>Guys are taking their last selfies with dead space eagles, and we start our way back to the evacuation point.</p>
          </div>
          <div>
            <img src={image64} alt='Baikonur' />
            <p><i>The final selfie with Buran</i></p>
          </div>
          <div className={css.group}>
            <p>
              Only after getting far enough from the cosmodrome perimeter, we felt ourself like we totally completed some really special mission. And it's hard to disagree: around 55 kilometers long trekking through the wild desert ways by foot with heavy backpacks, sneaking to the guarded territory of Baikonur cosmodrome, surviving in abandoned buildings, hiding from patrol cars and of course exploring  the place number one in the world of urbex!
            </p>
            <p>
              The whole way back we made it without any remarkable events. Only closer to our pick-up point we met another couple of illegal tourists, who were staying in a hangar at the same time with us but got scared to come closer as they thought that we were guards. That was a funny moment.
            </p>
            <p>
              By noon we finally got to our pick up point, where our driver was already waiting for us. Completely exhausted, we packed into the car, made a "mission happy end" photo and fell asleep.
            </p>
          </div>
          <div className={css.group}>
            <p>
              After coming back to Kyzylorda we had a celebration party in the local Kazakh bar and on the next day everyone started his way back home or continued traveling. But one thing stayed the same for all – we were the first organized illegal tour group to Baikonur and the mission was completed!
            </p>
          </div>
          <div className={css.callToAction}>
            <h1>
              Dear Friend!
            </h1>
            <div className={css.group}>
              <p>
                Now you have got a really rare chance to take part in our series of author urbex tours to the legendary abandoned cosmodrome Baikonur!
              </p>
              <p>
                It is impossible to get into this place officially, because the hangars are considered to be in emergency condition. Also there is a big chance that they will be simply cut into metal in the near future as there is a lot of talk about it. The place is unique and we consider it a must-visit location!
              </p>
              <p>
                Together with a professional guide you will pass a 30 km long way by foot through the desert with wild animals, snakes and scorpions, explore abandoned underground nuclear missile sites and finally  see both the “Energy” carrier rocket and the “Burans” themselves!
              </p>
              <p>
                Don’t miss this one time opportunity to join a community of real adventurers as there are only 3 (!) places in each of our mini groups!
              </p>
              <p>
                Also if you are a group of friends, there is an option to organize an individual tour just for your team.
              </p>
            </div>
            <p className={css.highlight}>To get the detailed info about dates, prices and <u>conditions</u> please contact us by email, in WhatsApp or in socials</p>
            <div className={css.row}>
              <LinkArrow to={CONTACT.path}>Contact us</LinkArrow>
              <Link to={GENERAL.path + '#tour-details'} className={css.tourDetails}>Tour details</Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
