import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Arrow } from '../../assets/arrow_2.svg';
import css from './button-arrow.module.scss';

ButtonArrow.propTypes = {
  type: PropTypes.oneOf(['button', 'submit']),
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired
};

export default function ButtonArrow({ type, onClick, children }) {
  return (
    <button type={type} onClick={onClick} className={css.buttonArrow}>
      {children}
      <Arrow />
    </button>
  );
}
