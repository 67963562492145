import React from 'react';
import Layout from '../../components/layout/layout';
import css from './terms-and-conditions.module.scss';

export default function TermsAndConditions() {
  return (
    <Layout>
      <div className={css.termsAndConditions}>
        <h1>TERMS AND CONDITIONS</h1>
        <h2><b>1. TERMS AND CONDITIONS OF USE</b></h2>
        <p>
          Welcome to the Urbexstorm website (hereafter ”the Website”). This page (together with the documents referred to on it) tells you the terms and conditions on which the Website provides any of the listed services to our clients. Please read these terms and conditions carefully before ordering any services or other offered from the Website. By placing an order on the Website or through communication ways offered on it, you agree to be bound by these terms and conditions set out on the Website.
        </p>
        <p>
          If you have any questions relating to these terms and conditions please contact info@urbexstorm.com before you place any orders.
        </p>
        <p>
          If you do not agree to the terms of use and conditions, please do not agree to them on request, exit urbexstorm.com website and do not place any orders.
        </p>
        <h2><b>2. PRICE AND PAYMENT</b></h2>
        <p>
          The price of any services will be as quoted on our Website and may vary depending on numerous factors, except in cases of obvious error.
        </p>
        <p>
          Prices are liable to change at any time, but changes will not affect orders that have already been placed and/or paid for (payment confirmation will be provided by email or another way of communication chosen by a client).
        </p>
        <p>All prices mentioned are per person, unless otherwise is not specified separately.</p>
        <p>Not included in the prices:</p>
        <ol>
          <li>Travel costs to the start point of the tour.</li>
          <li>Personal insurances</li>
          <li>Special personal requirements and needs that may arise during the trip and not mentioned as included in the price of the chosen service.</li>
          <li>Local fees and taxes at the destination region.</li>
        </ol>
        <p>
          Prepayment for services should be made online using certified online payment services, mentioned by the manager during the booking process. We accept payment via any financial service permitted by payment services we work with. The rest of the payment can be made online together with the prepayment or on the day of service provision in cash at the meeting point if another option is not mentioned by the manager. If taking the cash option, you must have the exact amount of the rest of your payment either in EUR or USD. 
          All prices mentioned are per person, unless otherwise is not specified separately.
        </p>
        <h2><b>3. CANCELLATION AND REFUND</b></h2>
        <p><b>3.1</b>&nbsp;Prepayment refund</p>
        <p>
          In case you decide to cancel your prepaid booking 30 days before the day of start of the tour you will receive a full refund.  
          If cancellation takes place in a period of 29-14 days before the tour, you will receive a 50% refund.
          If you cancel later than 14 days before the tour, you will receive no refund. 
          If you do not appear at the start point on the tour date, the full prepayment will not be refunded. However you will be offered to join another group in case such one is scheduled on another date.
        </p>
        <p><b>3.2</b>&nbsp;Entire tour cost refund</p>
        <p>
          If you do not reach the main target of the tour (abandoned hangars with spacecrafts) during the process of the tour since the moment it started for reasons caused by third persons, the entire amount of the tour cost will be returned to you except prepayment.
        </p>
        <p>
          If the reason is different and it’s not the fault of the Guide (for example you sprained your leg, got sick/tired, refused part of the trip etc), then the whole amount is not refundable. 
        </p>
        <p>
          If something happens to the Guide and he can’t lead the group, the whole amount will be refunded.
        </p>
        <p>
          <i>
            Please be aware that we reserve the right to cancel any order, before or after proceeding it, and will notify you immediately if any cancellation happens. It can rarely happen in case of force majeure event. You will be refunded 100% with subtraction of order processing fees for orders canceled by our side. At the same time we are not responsible for your additional costs incurred such as transport, insurance, visas and other travel-related goods and services costs.
          </i>
        </p>
        <h2><b>4. CONFIRMATION</b></h2>
        <p>
          After you complete the payment process you will be sent a notification to the email address, provided in the booking form on our website or other method of communication you mentioned.
        </p>
        <h2><b>5. SAFETY AND LEGAL ASPECTS</b></h2>
        <p>
          Some of the tours offered by urbexstorm™ team were designed according to the traditional urban exploration ideology, so you may accept a risk to get captured by a police. Such accidents happened before and usually ended up with a couple hours ID information check in the local police department with the following permanent release. The facilities you are going to explore are abandoned, however unauthorized visits to some of them are still lying in the “gray zone” of the legislative field. By following all guide’s instructions the chance to get stopped by a police will be minimized. However, despite this we would strongly recommend that you familiarize yourself with the theoretical aspect of the ideology of the urban exploration movement, if you are not directly related to it. Also we always welcome you to clarify safety and legal aspects of each specific tour offered before booking with our manager as they can vary.
        </p>
        <h2><b>6. AGE AND HEALTH REQUIREMENTS</b></h2>
        <p>
          <b>6.1</b>&nbsp;
          Age requirements: we serve only adult travelers over 21 years old without exceptions. 
          Throughout the trip you will carry all the responsibility for yourself and your goods.
        </p>
        <p>
          <b>6.2</b>&nbsp;
          Some of our tours require good physical shape and endurance. 
          The type of physical   activities  is described for each tour. Please soberly assess your abilities. All responsibility  for your health and physical condition during the tour lies on you. You can always check physical requirements for each tour in detail with our manager before booking a trip.
        </p>
        <h2><b>RISKS</b></h2>
        <p>
          All the tours we offer include well-thought-out and thoroughly tested routes accompanied by a highly professional guide, however the places we are visiting and related conditions can include unpredictable risks and dangers. By booking with us you agree to take responsibility for yourself, your actions and your health throughout the trip.
        </p>
        <h2><b>8. INSURANCE</b></h2>
        <p>We strongly recommend all our customers to have valid full insurance. Otherwise you carry a risk to incur all costs and losses on your own.</p>
        <h2><b>9. DOCUMENTS</b></h2>
        <p>
          Make sure your documents are valid and acceptable by the country of your touristic destination. 
          Some of the countries ask for a visa or other necessary documents and entry permits. We do not take responsibility for you not reaching the start point of the tour because of the documents problems.
        </p>
        <h2><b>10. EVENTS OUTSIDE OUR CONTROL</b></h2>
        <p>We will not be liable or responsible for any failure to perform, or delay in performance of any of our obligations under a contract that is caused by events outside our reasonable control</p>
        <p>(a "force majeure event").</p>
        <p>A Force Majeure Event includes any act, event, non-happening, omission or accident beyond both our reasonable control  and includes in particular (without limitation) the following:</p>
        <ol>
          <li>Strikes, lock-outs or other industrial action;</li>
          <li>Civil commotion, riot, invasion, terrorist attack or threat of terrorist attack, war (whether declared or not) or threat or preparation for war;</li>
          <li>Fire, explosion, storm, flood, earthquake, subsidence, epidemic or other natural disaster;</li>
          <li>Impossibility of the use of railways, shipping, aircraft, motor transport or other means of  public or private transport;</li>
          <li>Impossibility of the use of public or private communications networks;</li>
          <li>The acts, decrees, legislation, regulations or restrictions of any government</li>
        </ol>
        <h2><b>11. PRIVACY</b></h2>
        <p>We understand and respect your privacy, and we only ask for the data necessary to process your orders as well as to make our services better. Please carefully read the information written below concerning our usage of the data we gain from you:</p>
        <ul>
          <li>
            All personal data which we gain access to is the information provided by our clients through our website and/or communication, and cookies of the website.
          </li>
          <li>
            Means of communication (“communication”) include but aren’t limited to:
            <ul>
              <li>Facebook and Messenger</li>
              <li>What’sApp</li>
              <li>Email</li>
              <li>Telegram</li>
              <li>Verbal by telephone and/or live meeting.</li>
            </ul>
          </li>
          <li>All personal data which we gain access to on permission of our customers is used only to help us  provide higher standards of services and a better experience.</li>
          <li>All personal data apart from the data essential for providing preparation and provision of an order stays with us and does not get transferred to third parties.</li>
          <li>Some of the communication services can be used by us for sending info letters about  our rare offers and exclusive events. You can always unsubscribe from such a distribution.</li>
          <li>If you do not agree to this privacy policy, please do not agree to them on request, do not place any orders and exit the website.</li>
        </ul>
        <h2><b>12. WRITTEN COMMUNICATION</b></h2>
        <p>When using the website, you accept that communication with us will be electronic. We will contact you by e-mail or provide you with information by posting notices on the website and/or through communication. For contractual purposes, you agree to this electronic means of communication and you acknowledge that all contracts, notices, information and other communications that we provide to you electronically comply with any legal requirement that such communications be in writing. This condition does not affect your statutory rights.</p>
        <h2><b>13. TERMINATION</b></h2>
        <p>We reserve the right to decline a new order under these terms and conditions. If we consider there to be a risk of liability or loss to us or our partners we may take any action deemed necessary to prevent such a liability or loss from occurring. The company or any of our partners working with us may also, in certain circumstances, ask you not to return to any point of service provision and/or order any services, and we would typically respect the partner’s decision in this regard.</p>
        <h2><b>14. TERMS OF RESPONSE</b></h2>
        <p>All our customers who book any of our tours are fully responsible for their actions, which should not be dangerous for other participants in the group or our employees, same as should not infringe on their honor and dignity. Otherwise we reserve the right to remove you from the tour for inappropriate behavior without refund.</p>
        <h2><b>15. MEDIA</b></h2>
        <p>
          <b>15.1</b>&nbsp;
          It is allowed to take photos and record videos on all our tours for personal use only. 
          Any commercial content creation must be specified in advance before booking any of our 
          services.
        </p>
        <p>
          <b>15.2</b>&nbsp;
          Some sections of our tourist routes may contain sensitive trade secrets information. 
          Our guides may prohibit you from taking any photo or video recording on these sections 
          of the route.
        </p>
        <p>
          <b>15.3</b>&nbsp;
          Full or partial use of materials presented on our website is permitted only if a direct link to 
          the source is provided.
        </p>
        <h2><b>16. SEVERABILITY</b></h2>
        <p>If any of these terms and conditions or any provisions of a contract are determined by any competent authority to be invalid, unlawful or unenforceable to any extent, such term, condition or provision will to that extent be severed from the remaining terms, conditions and provisions which will continue to be valid to the fullest extent permitted by law.</p>
        <h2><b>17. ENTIRE AGREEMENT</b></h2>
        <p>These terms and conditions and any document expressly referred to in them represent the entire agreement in relation to the subject matter of any contract and supersede any prior agreement, understanding or arrangement between us, whether oral or in writing. We each acknowledge that, in entering into a contract, neither party has relied on any representation, undertaking or promise given by the other or be implied from anything said or written in negotiations between us prior to such contract except as expressly stated in these terms and conditions. Neither party shall have any remedy in respect of any untrue statement made by the other, whether orally or in writing, prior to the date of any contract (unless such untrue statement was made fraudulently) and the other party's only remedy shall be for breach of contract as provided in these terms and conditions.</p>
        <h2><b>18. OUR RIGHT TO VARY THESE TERMS AND CONDITIONS</b></h2>
        <p>The company has the right to revise and amend these terms and conditions from time to time. Any changes we make to these terms and conditions will be posted on this page. You will be subject to the policies and terms and conditions in force at the time that you order service(s) from urbexstorm™.</p>
      </div>
    </Layout>
  );
}
