import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Close } from '../../assets/close.svg';
import { ReactComponent as Open } from '../../assets/open.svg';
import css from './block-info.module.scss';
import classNames from 'classnames';

BlockInfo.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default function BlockInfo({ id, title, children, onClick, isOpen }) {
  return (
    <div id={id} className={classNames(css.block, isOpen ? css.expanded : '')}>
      <div className={css.header} onClick={onClick}>
        <p>{title}</p>
        <button onClick={onClick}>
          {isOpen && <Close />}
          {!isOpen && <Open />}
        </button>
      </div>
      {isOpen && (
        <div className={css.body}>
          {children}
        </div>
      )}
    </div>
  );
}