import React from 'react';
import PropTypes from 'prop-types';
import previewImage02 from '../../assets/general/2-preview.webp';
import previewImage03 from '../../assets/general/3-preview.webp';
import previewImage04 from '../../assets/general/4-preview.webp';
import previewImage05 from '../../assets/general/5-preview.webp';
import previewImage06 from '../../assets/general/6-preview.webp';
import previewImage07 from '../../assets/general/7-preview.webp';
import previewImage08 from '../../assets/general/8-preview.webp';
import css from './gallery.module.scss';

Gallery.propTypes = {
  onClick: PropTypes.func.isRequired
}

export default function Gallery({ onClick }) {
  return (
    <div className={css.gallery}>
      <div className={css.container}>
        <div className={css.firstRow}>
          <div>
            <img src={previewImage02} alt='Buran' onClick={() => onClick(0)} />
          </div>
          <div>
            <img src={previewImage03} alt='Buran' onClick={() => onClick(1)} />
          </div>
        </div>
        <div className={css.secondRow}>
          <div>
            <img src={previewImage04} alt='Buran' onClick={() => onClick(2)} />
          </div>
          <div>
            <img src={previewImage05} alt='Buran' onClick={() => onClick(3)} />
          </div>
          <div>
            <img src={previewImage06} alt='Buran' onClick={() => onClick(4)} />
          </div>
        </div>
        <div className={css.thirdRow}>
          <div>
            <img src={previewImage07} alt='Buran' onClick={() => onClick(5)} />
          </div>
          <div>
            <img src={previewImage08} alt='Buran' onClick={() => onClick(6)} />
          </div>
        </div>
      </div>
    </div>
  );
}
