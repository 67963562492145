import React, { useState } from 'react';
import css from './contact.module.scss';
import Layout from '../../components/layout/layout';
import Form from '../../components/form/form';

export default function Contact() {
  const [successfullySubmited, setSuccessfullySubmited] = useState(false);

  return (
    <Layout>
      <main className={css.contact}>
        {!successfullySubmited && <h1>Contact us</h1>}
        {!successfullySubmited && (
          <div className={css.contactInfo}>
            <div className={css.group}>
              <h3>WhatsApp, Telegram, Phone:</h3>
              <p>+380 73 402 3949</p>
            </div>
            <div className={css.group}>
              <h3>Email:</h3>
              <p>info@urbexstorm.com</p>
            </div>
            <div className={css.group}>
              <h3>Follow us:</h3>
              <div className={css.inlineGroup}>
                <a href='https://www.instagram.com/urbexstorm/' target='_blank' rel='noreferrer'>Instagram</a>
                <span>|</span>
                <a href='https://www.facebook.com/urbexstorm' target='_blank' rel='noreferrer'>Facebook</a>
              </div>
            </div>
          </div>
        )}
        {!successfullySubmited && <Form onSubmit={() => setSuccessfullySubmited(true)} />}
        {successfullySubmited && (
          <div className={css.success}>
            <h1>Thank you!</h1>
            <p>
              We’ve received your inquiry and will get back to you shortly.
            </p>
          </div>
        )}
      </main>
    </Layout>
  );
}
