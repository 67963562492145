import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './utils/basic.scss';
import { BLOG, CONTACT, GENERAL, TERMS_AND_CONDITIONS } from './constants/routes';
import General from './pages/general/general';
import TermsAndConditions from './pages/terms-and-conditions/terms-and-conditons';
import Contact from './pages/contact/contact';
import Blog from './pages/blog/blog';

export default function App() {
  return (
    <Routes>
      <Route path={GENERAL.path} element={<General />} />
      <Route path={TERMS_AND_CONDITIONS.path} element={<TermsAndConditions />} />
      <Route path={CONTACT.path} element={<Contact />} />
      <Route path={BLOG.path} element={<Blog />} />
    </Routes>
  );
}
