import React, { useState } from 'react';
import css from './general.module.scss';
import Layout from '../../components/layout/layout';
import image02 from '../../assets/general/2.webp';
import image03 from '../../assets/general/3.webp';
import image04 from '../../assets/general/4.webp';
import image05 from '../../assets/general/5.webp';
import image06 from '../../assets/general/6.webp';
import image07 from '../../assets/general/7.webp';
import image08 from '../../assets/general/8.webp';
import ButtonArrow from '../../components/button/button-arrow';
import BookingModal from '../../components/booking-modal/booking-modal';
import { ReactComponent as FooterMask } from '../../assets/general/footer-mask.svg';
import { ReactComponent as HeaderMask } from '../../assets/general/header-mask.svg';
import { ReactComponent as ArrowTop } from '../../assets/scroll_arrow.svg';
import Offer from './offer';
import CallToAction from './call-to-action';
import Report from './report';
import TourDetails from './tour-details';
import Gallery from './gallery';
import Feedbacks from './feedbacks';

export default function General() {
  const images = [image02, image03, image04, image05, image06, image07, image08];
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  function getNextImageIndex() {
    if (selectedImageIndex === images.length - 1) {
      return 0;
    }

    return selectedImageIndex + 1;
  }

  function getPrevImageIndex() {
    if (selectedImageIndex === 0) {
      return images.length - 1;
    }

    return selectedImageIndex - 1;
  }

  return (
    <Layout>
      <div className={css.general}>
        <div className={css.title}>
          <div className={css.mask}>
            <HeaderMask />
            <h1><span>Baikonur </span><span>Tour</span></h1>
          </div>
        </div>
        <div className={css.container}>
          <Offer onClick={() => setShowPopup(true)} />
          <Gallery onClick={setSelectedImageIndex} />
          <CallToAction />
          <Report />
          <TourDetails />
          <Feedbacks />
        </div>
        <div className={css.footer}>
          <div className={css.mask}>
            <FooterMask />
            <ButtonArrow onClick={() => setShowPopup(true)} type='button'>
              Book now
            </ButtonArrow>
          </div>
        </div>
        {selectedImageIndex !== null && (
          <div className={css.imagePopup} onClick={() => setSelectedImageIndex(null)}>
            <div className={css.container}>
              <div className={css.prevArrow} onClick={(e) => {
                e.stopPropagation();
                setSelectedImageIndex(getPrevImageIndex());
              }}>
                <ArrowTop />
              </div>
              <div className={css.content}>
                <div className={css.close} onClick={() => setShowPopup(false)}>
                  ×
                </div>
                <img src={images[selectedImageIndex]} alt='Buran' />
              </div>
              <div className={css.nextArrow} onClick={(e) => {
                e.stopPropagation();
                setSelectedImageIndex(getNextImageIndex());
              }}>
                <ArrowTop />
              </div>
            </div>
          </div>
        )}
        {showPopup && <BookingModal close={() => setShowPopup(false)} />}
      </div>
    </Layout>
  );
}
