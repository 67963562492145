import React from 'react';
import { ReactComponent as Flashlight } from '../../assets/flashlight.svg';
import { ReactComponent as Tourist } from '../../assets/tourist.svg';
import { ReactComponent as Rocket } from '../../assets/rocket.svg';
import css from './call-to-action.module.scss';

export default function CallToAction() {
  return (
    <div className={css.callToAction}>
      <div className={css.container}>
        <div className={css.cards}>
          <div className={css.card}>
            <div className={css.circle}>
              <Tourist />
            </div>
            <p>
              Pass a specially designed 30 km long trekking route under the guidance of a professional guide with more then 12 years experience in urban exploration and 7 years experience in extreme hiking organisation.
            </p>
          </div>
          <div className={css.card}>
            <div className={css.circle}>
              <Rocket />
            </div>
            <p>
              Explore the territory of an abandoned part of cosmodrome Baikonur and see both the "Energy" carrier rocket and the "Burans" burried in the hangars.
            </p>
          </div>
          <div className={css.card}>
            <div className={css.circle}>
              <Flashlight className={css.flashlight} />
            </div>
            <p>
              Experience the adventure of your lifetime in the best traditions of URBEX culture.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
