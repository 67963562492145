import React from 'react';
import PropTypes from 'prop-types';
import css from './layout.module.scss';
import ScrollArrow from '../scroll-arrow/scroll-arrow';
import Navbar from './navbar/navbar';
import Footer from './footer/footer';
import { useLocation } from 'react-router-dom';
import { BLOG, TERMS_AND_CONDITIONS } from '../../constants/routes';

Layout.propTypes = {
  children: PropTypes.node
};

export default function Layout({ children }) {
  const location = useLocation();

  const showScrollArrow = location.pathname === TERMS_AND_CONDITIONS.path || location.pathname === BLOG.path;

  return (
    <div className={css.layout}>
      <Navbar />
      <div>
        {children}
        <Footer />
        {showScrollArrow && <ScrollArrow />}
      </div>
    </div>
  )
}