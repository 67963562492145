import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Arrow } from '../../assets/arrow_2.svg';
import css from './button-arrow.module.scss';
import { Link } from 'react-router-dom';

LinkArrow.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default function LinkArrow({ to, children }) {
  return (
    <Link to={to} className={css.buttonArrow}>
      {children}
      <Arrow />
    </Link>
  );
}
