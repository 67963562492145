import React, { useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { ReactComponent as UrbexstormLogo } from '../../../assets/usrbexstorm_logo.svg';
import { BLOG, CONTACT, GENERAL } from '../../../constants/routes';
import css from './navbar.module.scss';
import ButtonArrow from '../../button/button-arrow';
import BookingModal from '../../booking-modal/booking-modal';
import { ReactComponent as HamburgerMenu } from '../../../assets/burger-menu.svg';
import classNames from 'classnames';
import { ReactComponent as Wtsp } from '../../../assets/wtsp.svg';
import { ReactComponent as Fb } from '../../../assets/fb.svg';
import { ReactComponent as Inst } from '../../../assets/inst.svg';

export default function Navbar() {
  const [showPopup, setShowPopup] = useState(false);
  const [menuDropdownOpened, setOpenMenuDropdown] = useState(false);
  const location = useLocation();
  const menuContainerRef = useRef(null);

  function openInNewTab(url) {
    window.open(url, '_blank', 'noopener,noreferrer');
  }

  return (
    <nav className={css.navbar}>
      <ul>
        <li>
          <NavLink to={BLOG.path} reloadDocument>
            {BLOG.title}
          </NavLink>
        </li>
        <li>
          <NavLink to={CONTACT.path} reloadDocument>
            {CONTACT.title}
          </NavLink>
        </li>
        <li className={css.logo}>
          <NavLink to={GENERAL.path} reloadDocument>
            <UrbexstormLogo />
          </NavLink>
        </li>
        <li
          className={classNames(css.menu, menuDropdownOpened ? css.open : css.close)}
          ref={menuContainerRef}
          onMouseEnter={() => setOpenMenuDropdown(true)}
          onMouseLeave={() => setOpenMenuDropdown(false)}
          onClick={() => setOpenMenuDropdown(!menuDropdownOpened)}>
          <HamburgerMenu />
          <div onClick={() => setOpenMenuDropdown(false)}>
            ×
          </div>
          <ul className={classNames(css.dropdown, menuDropdownOpened ? css.visible : css.hidden)}>
            <li>
              <ButtonArrow onClick={() => setShowPopup(true)} type='button'>
                Book now
              </ButtonArrow>
            </li>
            <li>
              <NavLink to={GENERAL.path}>
                Baikonur tour
              </NavLink>
            </li>
            <li>
              <NavLink to={BLOG.path} reloadDocument>
                {BLOG.title}
              </NavLink>
            </li>
            <li>
              <NavLink to={CONTACT.path} reloadDocument>
                {CONTACT.title}
              </NavLink>
            </li>
            <li className={css.social}>
              <Inst onClick={() => openInNewTab('https://www.instagram.com/urbexstorm/')} />
              <Fb onClick={() => openInNewTab('https://www.facebook.com/urbexstorm')} />
              <Wtsp onClick={() => openInNewTab('https://wa.me/380734023949')} />
            </li>
          </ul>
        </li>
        <li>
          {location.pathname !== GENERAL.path && (
            <NavLink to={GENERAL.path}>
              Baikonur tour
            </NavLink>
          )}
          {location.pathname === GENERAL.path && (
            <ButtonArrow onClick={() => setShowPopup(true)} type='button'>
              Book now
            </ButtonArrow>
          )}
        </li>
      </ul>
      {showPopup && <BookingModal close={() => setShowPopup(false)} />}
    </nav>
  );
}
