import React, { useState } from 'react';
import { ReactComponent as ArrowTop } from '../../assets/scroll_arrow.svg';
import feedbacksContent from '../../constants/feedbacks.json';
import css from './feedbacks.module.scss';

export default function Feedbacks() {
  const [selectedFeedbackIndex, setSelectedFeedbackIndex] = useState(0);
  const feedback = feedbacksContent.feedbacks[selectedFeedbackIndex];

  function getNextIndex() {
    if (selectedFeedbackIndex === feedbacksContent.feedbacks.length - 1) {
      return 0;
    }

    return selectedFeedbackIndex + 1;
  }

  function getPrevIndex() {
    if (selectedFeedbackIndex === 0) {
      return feedbacksContent.feedbacks.length - 1;
    }

    return selectedFeedbackIndex - 1;
  }

  return (
    <div className={css.feedbacks}>
      <h1><span>Our </span><span>feedbacks</span></h1>
      <p>It is hard to express emotions by words, it is only possible to experience such an extreme adventure by your own, but here are the real feedbacks from expedition members:</p>
      <div className={css.buttonsGroup}>
        <div className={css.prevArrow} onClick={() => setSelectedFeedbackIndex(getPrevIndex())}>
          <ArrowTop />
        </div>
        <p>{selectedFeedbackIndex + 1}<span className={css.grey}>/{feedbacksContent.feedbacks.length}</span></p>
        <div className={css.nextArrow} onClick={() => setSelectedFeedbackIndex(getNextIndex())}>
          <ArrowTop />
        </div>
      </div>
      <div className={css.feedback}>
        <h3>{feedback.title}</h3>
        <div className={css.textGroup}>
          {feedback.messages.map((message, i) => (
            <p key={i}>{message}</p>
          ))}
        </div>
      </div>
    </div>
  );
}
