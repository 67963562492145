import React, { useEffect, useState } from 'react';
import { ReactComponent as ArrowTop } from '../../assets/scroll_arrow.svg';
import css from './scroll-arrow.module.scss';
import classNames from 'classnames';

export default function ScrollArrow() {
  const [showScrollArrow, setShowScrollArrow] = useState(false);

  useEffect(() => {
    function handleScroll() {
      const scrollY = window.scrollY;
      if (scrollY > 0 && !showScrollArrow) {
        setShowScrollArrow(true);
        return;
      }

      if (scrollY === 0 && showScrollArrow) {
        setShowScrollArrow(false);
        return;
      }
    }

    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [showScrollArrow]);

  return (
    <div
      id='scroll-arrow'
      className={classNames(css.scrollArrow, showScrollArrow ? css.visible : css.hidden)}
      onClick={() => window.scrollTo(0, 0)}>
      <ArrowTop />
    </div>
  );
}
