export const GENERAL = {
    path: '/',
    title: 'Home'
  },
  BLOG = {
    path: '/blog',
    title: 'Blog'
  },
  CONTACT = {
    path: '/contact',
    title: 'Contact'
  },
  TERMS_AND_CONDITIONS = {
    path: '/terms',
    title: 'Terms and conditions'
  };
