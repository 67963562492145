import { React, forwardRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './input.module.scss';

const Input = forwardRef(function Input(props, ref) {
  const {
    id,
    name,
    label,
    value: valueFromProps,
    onChange: onChangeFromProps,
    onFocus: onFocusFromProps,
    onBlur: onBlurFromProps,
    error,
    multiline = false,
    className,
    placeholder,
    ...otherProps
  } = props;

  const hasError = !!error;
  const [isFocused, setIsFocused] = useState(false);
  const { watch } = useFormContext();
  const watchedValue = watch(name);

  const onFocus = (e) => {
    setIsFocused(true);
    onFocusFromProps?.(e);
  };

  const onBlur = (e) => {
    setIsFocused(false);
    onBlurFromProps?.(e);
  };

  const inputProps = {
    name: name,
    id: id,
    ref: ref,
    onBlur: onBlur,
    onFocus: onFocus,
    onChange: onChangeFromProps,
    placeholder: placeholder
  };

  function renderInput() {
    if (multiline) {
      return <textarea {...otherProps} {...inputProps} className={css.textarea} rows={1} />;
    }

    return <input {...otherProps} {...inputProps} className={css.input} />;
  }

  return (
    <div
      className={classNames(
        css.container,
        !watchedValue ? css.empty : css.filled,
        isFocused ? css.focused : '',
        hasError ? css.error : '',
        otherProps.required ? css.required : '',
        className
      )}>
      <div className={css.labelsContainer}>
        <label className={css.label} htmlFor={id}>
          {label}
        </label>
        {renderInput()}
        <label className={css.required} htmlFor={id}>*</label>
        <label className={css.errorMessage} htmlFor={id}>
          {error}
        </label>
      </div>
    </div>
  );
});

Input.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  multiline: PropTypes.bool,
  placeholder: PropTypes.string,
  className: PropTypes.string
};

export default Input;
