import React from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as Wtsp } from '../../../assets/wtsp.svg';
import { ReactComponent as Fb } from '../../../assets/fb.svg';
import { ReactComponent as Inst } from '../../../assets/inst.svg';
import { TERMS_AND_CONDITIONS } from '../../../constants/routes';
import css from './footer.module.scss';

export default function Footer() {
  const year = new Date().getFullYear();

  function openInNewTab(url) {
    window.open(url, '_blank', 'noopener,noreferrer');
  }

  return (
    <div className={css.footer}>
      <div className={css.container}>
        <div className={css.social}>
          <Inst onClick={() => openInNewTab('https://www.instagram.com/urbexstorm/')} />
          <Fb onClick={() => openInNewTab('https://www.facebook.com/urbexstorm')} />
          <Wtsp onClick={() => openInNewTab('https://wa.me/380734023949')} />
        </div>
        <div className={css.copyright}>
          <p>
            © {year} URBEXSTORM
          </p>
        </div>
        <NavLink to={TERMS_AND_CONDITIONS.path} reloadDocument>
          {TERMS_AND_CONDITIONS.title}
        </NavLink>
      </div>
    </div>
  );
}
