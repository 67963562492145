import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import emailjs from 'emailjs-com';
import { Link } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import css from './form.module.scss';
import Input from './input';
import { TERMS_AND_CONDITIONS } from '../../constants/routes';
import classNames from 'classnames';
import ButtonArrow from '../button/button-arrow';

Form.propTypes = {
  onSubmit: PropTypes.func
};

export default function Form({ onSubmit }) {
  const serviceId = 'service_mm1coyk';
  const templateId = 'template_9qojzlm';
  const publicKey = '6z51aA8tn5r_GKp_b';

  const validationSchema = yup.object({
    name: yup.string().trim().required('This field must contain a message'),
    email: yup.string().trim().required('This field must contain a message').email('The specified email address is not valid'),
    consent: yup.string().trim().required('Required!').test('is-valid-consent', 'Required!', (value) => value === 'yes')
  })
  const methods = useForm({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema)
  });
  const {
    register,
    handleSubmit,
    clearErrors,
    reset,
    formState: { errors, isSubmitSuccessful }
  } = methods;

  const registerAndClearOnChange = function (inputName) {
    return register(inputName, {
      onChange: () => {
        if (errors[inputName]) {
          clearErrors(inputName);
        }
      }
    });
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  function handleFormSubmit(data) {
    if (data.consent !== 'yes') return;

    const newData = {
      name: data.name,
      phone: data.phone,
      email: data.email,
      message: data.message
    };

    emailjs.send(serviceId, templateId, newData, publicKey);
    onSubmit();
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleFormSubmit)} className={css.form} autoComplete='off'>
        <div className={css.inputGroup}>
          <Input
            id='name'
            label='Full name'
            error={errors?.name?.message}
            required
            {...registerAndClearOnChange('name')}
          />
        </div>
        <div className={css.inputGroup}>
          <Input
            id='email'
            label='Email'
            error={errors?.email?.message}
            required
            {...registerAndClearOnChange('email')}
          />
          <Input
            id='phone'
            label='WhatsApp/Telegram (optional)'
            error={errors?.phone?.message}
            {...registerAndClearOnChange('phone')}
          />
        </div>
        <div className={css.inputGroup}>
          <Input
            id='message'
            label='Message (optional)'
            error={errors?.message?.message}
            multiline
            {...registerAndClearOnChange('message')}
          />
        </div>
        <label className={classNames(css.consent, errors?.consent?.message ? css.error : '')}>
          <input
            type='checkbox'
            id='consent'
            className={css.checkbox}
            value='yes'
            {...registerAndClearOnChange('consent')}
          />
          I agree with <Link to={TERMS_AND_CONDITIONS.path} reloadDocument>{TERMS_AND_CONDITIONS.title}</Link><br />
          <label className={css.errorMessage} htmlFor='consent'>
            {errors?.consent?.message}
          </label>
        </label>
        <ButtonArrow type='submit'>Send message</ButtonArrow>
      </form>
    </FormProvider>
  )
}
