import React, { useState } from 'react';
import classNames from 'classnames';
import css from './tour-details.module.scss';
import BlockInfo from './block-info';

export default function TourDetails() {
  const [expandedBlockIndex, setExpandedBlockIndex] = useState(0);

  function onBlockClick(e, index) {
    e.stopPropagation();
    if (expandedBlockIndex === index) {
      setExpandedBlockIndex(null);
      return;
    }
    const oldIndex = expandedBlockIndex ?? 0;
    const offsetIndex = index > oldIndex ? oldIndex : index;
    const offset = document.getElementById(`block-${offsetIndex}`);
    window.scrollTo({ top: offset.offsetTop, behavior: 'smooth' });
    setExpandedBlockIndex(index);
  }

  return (
    <div className={css.tourDetails}>
      <div className={css.expandedBlocks} id='tour-details'>
        <BlockInfo id='block-0' title='Prices and booking' onClick={(e) => onBlockClick(e, 0)} isOpen={expandedBlockIndex === 0}>
          <div className={css.block}>
            <div className={css.column}>
              <div className={css.row}>
                <h3>Prices</h3>
                <div className={css.textGroup}>
                  <div className={css.group}>
                    <h4>Group tour:</h4>
                    <p className={classNames(css.uppercase, css.white)}>per person - <span className={css.highlight}>950 EUR</span></p>
                  </div>
                  <div className={css.group}>
                    <h4>Personal tour:</h4>
                    <p className={classNames(css.uppercase, css.white)}>
                      1 person - <span className={css.highlight}>1350 EUR</span>
                      <br/>
                      2 persons - <span className={css.highlight}>2200 EUR</span>
                    </p>
                  </div>
                </div>
                <hr />
                <div className={css.textGroup}>
                  <p className={css.highlight}>
                    ! Basecamp accommodation in Kyzilorda, where you can store your luggage, transfers and provision for the trip are included in the price.
                  </p>
                  <p className={css.highlight}>
                    ! The price does not include flights to Kysilorda.
                  </p>
                </div>
              </div>
            </div>
            <div className={css.column}>
              <div className={css.row}>
                <h3>Booking</h3>
                <div className={css.textGroup}>
                  <p>
                    We are available for booking from September to November and from March to May.
                  </p>
                  <p>
                    In order to book a place in the group you should contact us through a website, accept our terms and conditions and make a prepayment for the trip -350 EUR with an online transfer. The rest amount you pay in cash ( USD/EUR ) on arrival.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </BlockInfo>
        <BlockInfo id='block-1' title='Required equipment' onClick={(e) => onBlockClick(e, 1)} isOpen={expandedBlockIndex === 1}>
          <div className={css.block}>
            <div className={css.column}>
              <div className={css.row}>
                <h3>Clothes</h3>
                <div className={css.textGroup}>
                  <p>Not bright! Sandy, gray, black and without flickers! You can buy something on the spot, but it's better to prepare everything in advance:</p>
                  <ul>
                    <li>
                      Membrane waterproof and windproof jacket
                    </li>
                    <li>
                      Fleece jacket
                    </li>
                    <li>
                      Trekking synthetic quick-drying pants
                    </li>
                    <li>
                      Thermal underwear, top and bottom
                    </li>
                    <li>
                      2 pairs of socks
                    </li>
                    <li>
                      Hat for sun protection or buff
                    </li>
                    <li>
                      Thin protective or rubberised work gloves
                    </li>
                  </ul>
                </div>
              </div>
              <div className={css.row}>
                <h3>Equipment</h3>
                <div className={css.textGroup}>
                  <ul>
                    <li>
                      Hardback backpack with belt: 30-40 liters
                    </li>
                    <li>
                      Headlamp, better with a red-light mode available
                    </li>
                    <li>
                      Power bank
                    </li>
                    <li>
                      Metal mug (there will be a burner for making tea)
                    </li>
                    <li>
                      Sleeping bag + mat
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={css.column}>
              <div className={css.row}>
                <h3>Shoes</h3>
                <div className={css.textGroup}>
                  <ul>
                    <li>
                      Trekking shoes/boots with hard soles and water protection. Be sure to get used to them in advance!
                    </li>
                    <li>
                      You can take light slippers/crocs to wear during camping stops.
                    </li>
                  </ul>
                </div>
              </div>
              <div className={css.row}>
                <h3>Hygiene</h3>
                <div className={css.textGroup}>
                  <ul>
                    <li>
                      Toothbrush and paste
                    </li>
                    <li>
                      Toilet paper
                    </li>
                    <li>
                      Wet wipes
                    </li>
                    <li>
                      Personal first aid kit + medicines you can't do without
                    </li>
                    <li>
                      Personal care products
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </BlockInfo>
        <BlockInfo id='block-2' title='What You Will Get' onClick={(e) => onBlockClick(e, 2)} isOpen={expandedBlockIndex === 2}>
          <div className={css.block}>
            <div className={css.column}>
              <div className={css.row}>
                <div className={css.textGroup}>
                  <ul>
                    <li>Accomodation and luggage storage in Kyzilorda</li>
                    <li>Transfer from Kyzilorda to the start point in a desert steppe and back</li>
                    <li>Professional support of the guide with years of experience in organizing extreme urbex and outdoor trekking tours</li>
                    <li>Provision for hiking</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={css.column} />
          </div>
        </BlockInfo>
        <BlockInfo id='block-3' title='! Legal and safety aspects' onClick={(e) => onBlockClick(e, 3)} isOpen={expandedBlockIndex === 3}>
          <div className={css.block}>
            <div className={css.column}>
              <div className={css.row}>
                <h3>Legality</h3>
                <div className={css.textGroup}>
                  <p className={css.highlight}>
                    ! This tour was designed according to the traditional urban exploration ideology, so you may accept a risk to get captured by a police. Such accidents happened before and usually were ending up with couple of hours ID information check in the local police department. The facility you are going to explore is abandoned and no longer belongs to Russia, however an active cosmodrome site is located right next to it. By following all guide's instructions, the chance to get captured will be minimized.
                  </p>
                </div>
              </div>
            </div>
            <div className={css.column}>
              <div className={css.row}>
                <h3>Safety</h3>
                <div className={css.textGroup}>
                  <p className={css.highlight}>
                    ! The major way you are going to pass is going to be by foot in the wild conditions of the Kazakh desert steppe, so trekking and camping experience is important. Depending on a season temperatures may vary from +5 to +33 Celsius.
                  </p>
                  <p className={css.highlight}>
                    ! You need to pass all the way to cosmodrome in one night, the distance is about 25-30 km. Timing on the track is very important, soberly assess your capabilities!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </BlockInfo>
      </div>
    </div>
  );
}
