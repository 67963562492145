import React from 'react';
import PropTypes from 'prop-types';
import css from './offer.module.scss';
import ButtonArrow from '../../components/button/button-arrow';
import classNames from 'classnames';

Offer.propTypes = {
  onClick: PropTypes.func.isRequired
}

export default function Offer({ onClick }) {
  return (
    <div className={css.offer}>
      <div className={css.container}>
        <h1>
          Experience the Urbex expedition to an abandoned cosmodrome
        </h1>
        <div className={css.textGroup}>
          <p>
            Explore the most legendary destination in the world of urban exploration together with a professional urbex guide
          </p>
          <ButtonArrow onClick={onClick} type='button'>
            Book now
          </ButtonArrow>
        </div>
        <div className={css.cards}>
          <div className={css.card}>
            <p className={css.title}>Pricing begins at:</p>
            <div className={css.body}>
              <p className={classNames(css.highlight, css.price)}>€950</p>
              <p className={css.hint}>Per person</p>
            </div>
          </div>
          <div className={css.card}>
            <p className={css.title}>Duration</p>
            <div className={css.body}>
              <div className={css.subGroup}>
                <p className={classNames(css.highlight, css.duration)}>4</p>
                <p className={css.value}>days</p>
              </div>
            </div>
          </div>
          <div className={css.card}>
            <p className={css.title}>Trekking distance:</p>
            <div className={css.body}>
              <div className={css.subGroup}>
                <p className={classNames(css.highlight, css.distance)}>30</p>
                <p className={css.value}>km</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
